import { useToastContext } from 'src/context/toast'
import { useCallback, useEffect, useState } from 'react'
import axios from 'axios'

export function useErrorHandler() {
  const { addToastNotice } = useToastContext()
  const [error, setError] = useState<any>()

  const handleError = useCallback(
    (error: unknown) => {
      let message = 'Es ist ein Fehler aufgetreten'
      if (axios.isAxiosError(error)) {
        if (axios.isCancel(error) && error.config) {
          console.log('Request cancelled - url: ' + error.config.url)
          return
        } else if (error.status === 401) {
          console.log('401 error, waiting for usercontext to redirect to login')
          return
        } else if (error.response) {
          message = error.response.data.message || message
        }
      } else if (error instanceof Error) {
        //show real error message to User
        //message = error.message
      } else {
        console.log('Unknown error')
      }

      addToastNotice({
        type: 'ERROR',
        message: message,
      })
      setError(null)
      console.log(error)
    },
    [addToastNotice],
  )

  useEffect(() => {
    if (error) {
      if (
        error.responseType === 'blob' &&
        error.response?.type === 'application/json'
      ) {
        error.response
          .text()
          .then((responseText: any) => {
            handleError({
              status: error.status,
              response: responseText,
            })
          })
          .catch(console.log)
      } else {
        handleError(error)
      }
    }
  }, [error, handleError])

  return setError
}
