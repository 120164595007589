import { resolveRestUrl } from 'src/utils/urlUtil'
import axios, { ResponseType } from 'axios'

export function jsonGet<T>(
  url: string,
  data?: unknown,
  abortController?: AbortController,
  responseType?: ResponseType,
) {
  return doRequest<T>(
    url,
    'GET',
    data,
    { 'Content-Type': 'application/json; charset=UTF-8' },
    abortController,
    responseType,
  )
}

export function jsonPost<T>(
  url: string,
  data?: unknown,
  abortController?: AbortController,
  responseType?: ResponseType,
) {
  return doRequest<T>(
    url,
    'POST',
    data,
    { 'Content-Type': 'application/json; charset=UTF-8' },
    abortController,
    responseType,
  )
}

export function rawPost<T>(
  url: string,
  data?: unknown,
  headers?: HeadersInit,
  abortController?: AbortController,
  responseType?: ResponseType,
) {
  return doRequest<T>(
    url,
    'POST',
    data as BodyInit,
    headers,
    abortController,
    responseType,
  )
}

export function rawGet<T>(
  url: string,
  headers?: HeadersInit,
  abortController?: AbortController,
  responseType?: ResponseType,
) {
  return doRequest<T>(
    url,
    'GET',
    undefined,
    headers,
    abortController,
    responseType,
  )
}

const doRequest = <T,>(
  url: string,
  method: string,
  data: unknown,
  headers = {},
  abortController?: AbortController,
  responseType?: ResponseType,
): Promise<T> => {
  return new Promise<T>((resolve, reject) => {
    const withCredentials = window.location.host.indexOf(':3000') !== -1
    axios<T>({
      responseType: responseType,
      method: method,
      url: resolveRestUrl(url),
      data: data,
      withCredentials: withCredentials,
      headers: { ...headers },
      ...(abortController && { signal: abortController.signal }),
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error: unknown) => {
        reject(error)
      })
  })
}

export const isErrorResponse = (error: any): boolean => {
  return error.hasOwnProperty('type') && error.hasOwnProperty('message')
}
