import { User } from 'src/flow-types/Trip'

import { useErrorHandler } from 'src/hooks'
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { AI_FILE_PARSE } from 'src/utils/const'

import { jsonGet, rawPost } from 'src/utils/httpUtil'
import { AVAILABLE_PLUGIN } from 'src/pages/settings/tabs/linkedApps/availablePlugins'

type UserContextType = {
  user: ERUser
  isProd: boolean
  refreshUserContext: () => void
  getLocalUserSettings: (name: string) => any | undefined
  setLocalUserSettings: (name: string, value: any) => void
  updateFileParse: (fileParseStatus: AI_FILE_PARSE) => Promise<null>
  isTrial: boolean
  isBasic: boolean
  isTeam: boolean
  isTeamAdmin: boolean
  isTeamManagerOrAdmin: boolean
}

export enum USER_ROLE {
  TRIAL_USER = 'TRIAL_USER',
  BASIC_USER = 'BASIC_USER',
  REGULAR_USER = 'REGULAR_USER',
  BUSINESS_USER = 'BUSINESS_USER',
  BUSINESS_ADMIN = 'BUSINESS_ADMIN',
  BUSINESS_MANAGER = 'BUSINESS_MANAGER',
}

export type ERUser = {
  canExport: boolean
  companyId: number | null
  email: string
  newUser: boolean
  noLoginUsersEnabled: boolean
  notifications: Array<string>
  packageInfo: {
    canAccountBeProlonged: boolean
    canMakePayments: boolean
    expired: boolean
    expires: string
    freeTrips: number
    name: string
    packageType: string
  }
  pluginType: AVAILABLE_PLUGIN | null
  team: {
    approvalWorkflow: boolean
    teamGroupPermission: boolean
  } | null
  user: User
  userRole: USER_ROLE
  isAuthenticated: boolean | null
  aiFileParse: AI_FILE_PARSE
}

const initialState: ERUser = {
  canExport: false,
  companyId: null,
  email: '',
  newUser: false,
  noLoginUsersEnabled: false,
  notifications: [],
  packageInfo: {
    canAccountBeProlonged: false,
    canMakePayments: false,
    expired: false,
    expires: '',
    freeTrips: 0,
    name: '',
    packageType: '',
  },
  pluginType: null,
  team: {
    approvalWorkflow: false,
    teamGroupPermission: false,
  },
  user: {
    firstName: '',
    id: '',
    lastName: '',
    status: '',
  },
  userRole: USER_ROLE.BASIC_USER,
  aiFileParse: AI_FILE_PARSE.NONE,
  isAuthenticated: false,
}

const UserContext = createContext<UserContextType>({} as UserContextType)

type UserProviderProps = {
  children: ReactNode
}

function UserProvider(props: UserProviderProps): ReactNode {
  const { children } = props
  const handleError = useErrorHandler()
  const [user, setUser] = useState<ERUser>(initialState)
  const [isTrial, setIsTrial] = useState(false)
  const [isBasic, setIsBasic] = useState(false)
  const [isTeam, setIsTeam] = useState(false)
  const [isTeamAdmin, setIsTeamAdmin] = useState(false)
  const [isTeamManagerOrAdmin, setIsTeamManagerOrAdmin] =
    useState<boolean>(false)

  const getLocalUserSettings = (name: string): string | undefined => {
    if (user.user?.id) {
      const settingsFromStorage = localStorage.getItem('userSettings')
      const settings = settingsFromStorage
        ? JSON.parse(settingsFromStorage)
        : null

      return name &&
        settings &&
        settings[user.user.id] &&
        settings[user.user.id][name]
        ? settings[user.user.id][name]
        : null
    }
  }

  useEffect(() => {
    setIsTeamAdmin(USER_ROLE.BUSINESS_ADMIN === user.userRole)
    setIsTeamManagerOrAdmin(
      [USER_ROLE.BUSINESS_MANAGER, USER_ROLE.BUSINESS_ADMIN].includes(
        user.userRole,
      ),
    )
    setIsTeam(!!user.team)
    setIsTrial(USER_ROLE.TRIAL_USER === user.userRole)
    setIsBasic(USER_ROLE.BASIC_USER === user.userRole)
  }, [user])

  const setLocalUserSettings = useCallback(
    (name: string, value: any) => {
      if (user.user?.id) {
        const settingsFromStorage = localStorage.getItem('userSettings')
        const settings = settingsFromStorage
          ? JSON.parse(settingsFromStorage)
          : {}

        localStorage.setItem(
          'userSettings',
          JSON.stringify({
            ...settings,
            [user.user.id]: {
              ...(settings[user.user.id] ?? {}),
              [name]: value,
            },
          }),
        )
      }
    },
    [user.user.id],
  )

  const refreshUserContext = useCallback(
    (abortController?: AbortController) => {
      jsonGet<ERUser>('user/public/user-context', undefined, abortController)
        .then((response) => {
          if (!response) {
            // 200 but no response
            if (!window.location.pathname.startsWith('/public')) {
              window.location.href =
                '/public/login?redirect=' +
                encodeURIComponent(
                  window.location.pathname + window.location.search,
                )
            }
          } else {
            setUser({
              ...response,
              isAuthenticated: true,
            })
          }
        })
        .catch((error) => {
          setUser(initialState)
          handleError(error)
        })
    },
    [handleError],
  )

  useEffect(() => {
    const abortController = new AbortController()
    refreshUserContext(abortController)
    return () => {
      abortController.abort()
    }
  }, [refreshUserContext])

  const updateFileParse = (fileParseStatus: AI_FILE_PARSE): Promise<null> => {
    return new Promise(function (resolve, reject) {
      rawPost<void>('user/settings/ai_file_parse/update/' + fileParseStatus)
        .then((response) => {
          refreshUserContext()
          resolve(null)
        })
        .catch((error) => {
          handleError(error)
          reject(error)
        })
    })
  }

  const isProd = window.location.host === 'app.einfach-reisekosten.de'

  return (
    <UserContext.Provider
      value={{
        user,
        isProd,
        refreshUserContext,
        getLocalUserSettings,
        setLocalUserSettings,
        isTeam,
        isTrial,
        isBasic,
        isTeamAdmin,
        isTeamManagerOrAdmin,
        updateFileParse,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

function useUserContext() {
  const ctx = useContext(UserContext)

  if (!ctx) {
    throw new Error('useUserContext must be used within UserProvider')
  }

  return ctx
}

export { useUserContext, UserProvider }
