import {
  ConversionStatus,
  EXPENSE_TYPE,
  PLUGIN_ERROR_CODE,
  PLUGINS,
  TRIP_APPROVAL_STATUS,
  TRIP_EXPORT_STATUS,
} from 'src/utils/const'
import { AssistantPollingFile } from 'src/context/assistant'
import { TripValidation } from 'src/flow-types/TripValidation'

export type Duration = {
  totalMillis: number
  days: number
  hours: number
  minutes: number
}

export enum VAT_TYPE {
  VAT19 = 'VAT19',
  VAT7 = 'VAT7',
  NONE_AUS = 'NONE_AUS',
  NONE_IN = 'NONE_IN',
}

export type VatRate = {
  value: string
  label: string
}
export type TypeVats = {
  [key in VAT_TYPE]?: number
}

export type File = {
  contentType: string
  conversionStatus: ConversionStatus | null
  convertedFiles: Array<File> | null
  created: string
  fileStatus: string
  height: number
  id: string
  realFileName: string
  size: number
  width: number
  index?: number | null
}

export type Currency = {
  id: number
  currencyCode: string
  date: string
  rate: number
  interbankRate: number
  manualRate: boolean
  bestRate: boolean
}

export type Location = {
  address: string
  id: number
  latitude: number
  longitude: number
  shortAddress: string
  index: number
  iso2CountryCode: string | null
}

export type PrivateRideTariff = {
  active: boolean
  costs: number
  global: boolean
  id: string
  title: string
}

export enum ExpenseCategory {
  ACCOMODATION = 'ACCOMODATION',
  RIDE = 'RIDE',
  EXTRA = 'EXTRA',
  COMPENSATION = 'COMPENSATION',
  CATERING = 'CATERING',
}

export type ExpenseGeneral = {
  calculatedCosts: number
  category: ExpenseCategory
  class: 'expense'
  currency: Currency
  date: string | null
  description: string
  enteredCosts: number
  expenseNumber: string | null
  files: Array<File>
  hideInfo: boolean
  id: string
  newExpense: boolean
  onlyVAT: boolean
  onlyVatEligible: boolean
  paymentType: string
  type: EXPENSE_TYPE
  vatRate: VAT_TYPE | null
  fileParseData: AssistantPollingFile | null
  fromFileParse: boolean
}

export enum VehicleType {
  CAR = 'CAR',
  MOTORCYCLE = 'MOTORCYCLE',
  MOPED = 'MOPED',
}

export const VEHICLE_TYPES_PROPS = {
  [VehicleType.CAR]: {
    label: 'Kraftfahrzeug',
  },
  [VehicleType.MOTORCYCLE]: {
    label: 'Motorrad',
  },
  [VehicleType.MOPED]: {
    label: 'Moped',
  },
}

export type ExpensePrivateRide = {
  calculatedCosts: number
  category: string
  class: 'privateRide'
  commuterLumpSum: boolean
  costsPerKm: number
  distance: number
  enteredCosts: number
  hideInfo: boolean
  id: string
  locationIds: Array<number>
  locations: Array<Location>
  newExpense: boolean
  passengers: number
  privateRideTariff: PrivateRideTariff | null
  type: EXPENSE_TYPE
  vehicleType: VehicleType
}

export type ExpenseFlatAccomodation = {
  calculatedCosts: number
  category: string
  class: 'flatAccomodation'
  enteredCosts: number
  hideInfo: boolean
  id: string
  newExpense: boolean
  nightsSpent: number
  countryCode: CountryCode
  type: EXPENSE_TYPE
}

export type Expense =
  | ExpenseGeneral
  | ExpensePrivateRide
  | ExpenseFlatAccomodation

type Approval = {
  comment: string | null
  date: string
  id: string
  status: TRIP_APPROVAL_STATUS
}

export type Group = {
  id: string
  teamGroup: boolean
  title: string
  user: string | null
}

export type PrivateMealsTariff = {
  active: boolean
  breakfast: number
  dinner: number
  global: boolean
  id: string
  lunch: number
  title: string
}

export type PrivateCateringTariff = {
  active: boolean
  global: boolean
  id: string
  eightHourTariff: number
  fullDayTariff: number
  tariff2: number /*deprecated*/
  title: string
}
export type CountryCode = {
  value: string
  iso2CountryCode: string
  substate: boolean
}

export type BundledMeal = {
  breakfast: number
  breakfastCosts: number
  costs: number
  dinner: number
  dinnerCosts: number
  id: string
  lunch: number
  lunchCosts: number
  countryCode: CountryCode
}

export type RouteLap = {
  class: 'lap'
  date: string | null
  id: string
  index: number
  location: Location
}

export type RoutePause = {
  class: 'pause'
  duration: Duration | null
  endDate: string | null
  id: string
  index: number
  startDate: string | null
}

export type RouteItem = RouteLap | RoutePause

export type User = {
  firstName: string
  id: string
  lastName: string
  status: string
}

type Export = {
  date: string
  errorCode: PLUGIN_ERROR_CODE | null
  exportType: string
  pluginType: PLUGINS
  status: TRIP_EXPORT_STATUS
  voucherId: string | null
} | null

export type CountryTariff = {
  countryCode: CountryCode
  countryName: string
  fullCountryName: string
  fullCountryNameAndPrivateCateringTitle: string
  privateCateringTitle: string | null
  currencyCode: string | null
  subStateName: string | null
  eightHourTariff: number
  fullDayTariff: number
  tariffAccomodation: number
}

export type DailyCatering = {
  costs: number
  countryTariff: CountryTariff
  cateringTariff: 'NONE' | 'FULL_PAUSE_DAY' | 'EIGHT_HOURS' | 'FULL_DAY'
  date: string
  durationParts: Duration
  dayType: 'FIRST' | 'LAST' | 'INTERMEDIATE' | 'SINGLE'
  withoutOvernightStay: boolean
  privateCateringTariff: PrivateCateringTariff | null
}

export type Settings = {
  comment: boolean
  costCenter: boolean
  dirty: boolean
  expenseDate: boolean
  expenseNumber: boolean
  group: boolean
  project: boolean
  vatRate: boolean
}

export type DetailedMeal = {
  date: string
  breakfast: boolean
  // breakfastCosts: number
  costs: number
  dinner: boolean
  // dinnerCosts: number
  id: string
  lunch: boolean
  // lunchCosts: number
  countryCode: CountryCode
}

export type Trip = {
  approval: Approval
  cateringFee: boolean
  comment: string | null
  companyCosts: number
  costCenter: string | null
  costs: number
  costsMinusCompanyCosts: number
  dailyCaterings: Array<DailyCatering>
  duration: Duration
  expenses: Array<Expense>
  export: Export
  exportStatus?: TRIP_EXPORT_STATUS
  forEmployer: boolean
  group: Group | null
  id: string
  bundledMeals: Array<BundledMeal>
  detailedMeals: Array<DetailedMeal>
  privateCateringTariff: PrivateCateringTariff | null
  privateMealsTariff: PrivateMealsTariff | null
  project: string | null
  roundTrip: boolean
  roundTripChanged: boolean
  route: Array<RouteItem>
  title: string
  user: User
  vatCosts: TypeVats
  showMealsChanged?: boolean
  version: number
  twoDaysTrip: boolean
  withoutOvernightStay: boolean
}

export type EditorTrip = Trip & {
  dirty: boolean
}

export type ListingTrip = {
  approvalStatus: TRIP_APPROVAL_STATUS
  costLocation: string | null
  endDate: string | null
  exportStatus: TRIP_EXPORT_STATUS
  group: Group | null
  id: string
  jobNumber: string | null
  laps: Array<RouteLap>
  owner: User
  startDate: string | null
  title: string
  totalCosts: number
}

export type ValidatedTrip = {
  countryTariffs: Array<CountryTariff>
  dirty: boolean
  newTrip: boolean
  sessionTrip: boolean
  settings: Settings
  trip: EditorTrip
  tripValidation: TripValidation
  vatRates: Array<VatRate>
  selectedItem: SelectedItem | null
}

export type SelectedItem = {
  type: string
  id: string
  expandGallery: boolean
} | null
